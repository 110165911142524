import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import '../../App.css';
import { Controller, useForm } from "react-hook-form";
import {KeyDownNumber,DateFormat,IdentifyTypeName, GetAPIURL,GetAuthorization, DateThai} from '../../Utils/Function';
import { ThaiDatePicker } from "thaidatepicker-react";
import AsyncSelect from 'react-select/async';
import {SearchSubDistrict,SearchSchool} from '../../Utils/Search';
import moment from 'moment';
import {PDFViewCard} from '../../Components';
import ApplicationFormPDF from './ApplicationFormPDF';
const RegisterForm = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();
    const [_ModalFormState,setModalFormState] = useState(false);
    const [_ModalFileState,setModalFileState] = useState(false);
    const [_ModalPayment,setModalPayment] = useState(false);
    const [_ModalSubmit,setModalSubmit] = useState(false);

    const [_RegulationID,setRegulationID] = useState(null);
    const [_RegRoundID,setRegRoundID] = useState(null);
    const [_RegRound,setRegRound] = useState(null);
    const [_Applicant,setApplicant] = useState(null);
    const [_Application,setApplication]= useState(null);
    const [_IdentifyType,setIdentifyType] = useState(1);
    const [_BirthDay,setBirthDay] = useState('');
    const [_Age,setAge] = useState('');

    const [_FormStep,setFormStep] = useState(1);

    const _FileInputRef = useRef();
    const _FilePaymentSlipRef = useRef();
    const [_FileRequestID,setFileRequestID] = useState(undefined);
    const [_FileList,setFileList] = useState([]);
    
    const _PDFViewCard = useRef();
    const [_PaymentType,setPaymentType] = useState(1);
    const [_PaymentSlipFile,setPaymentSlipFile] = useState(null);
    const [_PaymentQR,setPaymentQR] = useState(true);
    const [_PaymentStatus,setPaymentStatus] = useState(false);
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(RegRoundID,RegulationID){
            setPaymentSlipFile(null);
            setPaymentQR(true);
            setModalFormState(false);
            setModalFileState(false);
            setPaymentStatus(false);
            _ResetValue();
            setRegRoundID(RegRoundID);
            _LoadRegRound(RegRoundID);
            _LoadApplicant(RegulationID);
            if(RegulationID != undefined){
                setRegulationID(RegulationID);
                _LoadApplicationForm(RegulationID);
                _LoadApplicationFile(RegulationID);
            }else{
                setRegulationID(null);
            }
            setModalFormState(true);
        }
      }));
      const _LoadRegRound = async(RegRoundID)=>{
        setRegRound(null);
        API({method : "get",url:"/Applicant/RegRound/"+RegRoundID}).then(res=>{
            if(res.data.Status){
                setRegRound(res.data.Response);
            }
        }).catch(error=>{

        });
      }
      const _LoadApplicant = async(RegulationID)=>{
        setRegRound(null);
        API({method : "get",url:"/Applicant/Detail"}).then(res=>{
            if(res.data.Status){
                const _obj = res.data.Response;
                setApplicant(_obj);
                if(RegulationID === undefined){
                    setValue('PrefixNameID',_obj.PrefixNameID);
                    setValue('FirstName',_obj.FirstName);
                    setValue('LastName',_obj.LastName);
                    setValue('IdentifyID',_obj.IdentifyID);
                    setIdentifyType(_obj.IdentifyType);
                    setBirthDay(_obj.BirthDay);
                    setValue('Mobile',_obj.MobileNumber);
                    const AgeCal = moment().diff(_obj.BirthDay,'years');
                    if(!isNaN(AgeCal)) setAge(AgeCal); else setAge('');
                }
            }
        }).catch(error=>{

        });
      }

    const _LoadApplicationForm = async(RegulationID)=>{
        API({method : "get",url:"/Application/"+RegulationID}).then(res=>{
            if(res.data.Status){
                const _obj = res.data.Response;
                setValue('PrefixNameID',_obj.PrefixNameID);
                setValue('FirstName',_obj.FirstName);
                setValue('LastName',_obj.LastName);
                setValue('IdentifyID',_obj.IdentifyID);
                setIdentifyType(_obj.IdentifyType);
                setBirthDay(_obj.BirthDay);
                setValue('Address',_obj.Address);
                setValue('Subdistrict',_obj.District);
                setValue('PostCode',_obj.PostCode);
                setValue('Mobile',_obj.Mobile);
                setValue('ParentMobile',_obj.ParentMobile);
                

                setValue('SchoolCode',_obj.School);
                setValue('GPA',_obj.GPA);
                setValue('GPAEnglish',_obj.GPAEnglish);
                setValue('GPAMath',_obj.GPAMath);
                setValue('GPAScience',_obj.GPAScience);

                setValue('Project',_obj.Project);


                setValue('FirstNameDad',_obj.FirstNameDad);
                setValue('LastNameDad',_obj.LastNameDad);
                setValue('AddressDad',_obj.AddressDad);
                setValue('WorkDad',_obj.WorkDad);
                setValue('PositionDad',_obj.PositionDad);
                setValue('SalaryDad',_obj.SalaryDad);

                setValue('FirstNameDad',_obj.FirstNameMom);
                setValue('LastNameMom',_obj.LastNameMom);
                setValue('AddressMom',_obj.AddressMom);
                setValue('WorkMom',_obj.WorkMom);
                setValue('PositionMom',_obj.PositionMom);
                setValue('SalaryMom',_obj.SalaryMom);

                const AgeCal = moment().diff(_obj.BirthDay,'years');
                if(!isNaN(AgeCal)) setAge(AgeCal); else setAge('');
                setApplication(_obj);
            }
        }).catch(error=>{

        });
    }
    const _Save = ()=>{
        if(getValues('PrefixNameID') == null || getValues('PrefixNameID') == undefined || Number(getValues('PrefixNameID')) === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุคำนำหน้าชื่อ'
            });
            return;
        }

        if(getValues('FirstName') == null || getValues('FirstName') == undefined || getValues('FirstName').length === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุชื่อ'
            });
            return;
        }
        if(getValues('LastName') == null || getValues('LastName') == undefined || getValues('LastName').length === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุนามสกุล'
            });
            return;
        }
        if(_BirthDay === undefined || _BirthDay === null){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุวันเดือนปีเกิด'
            });
            return;
        }
        if(getValues('Address') === null || getValues('Address') === undefined || getValues('Address').length === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุที่อยู่ปัจจุบัน'
            });
            return;
        }
        if(getValues('Subdistrict') === null || getValues('Subdistrict') === undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุที่ ตำบล / อำเภอ / จังหวัด'
            });
            return;
        }
        if(getValues('PostCode') == null || getValues('PostCode') == undefined || getValues('PostCode').length != 5){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุรหัสไปรษณีย์'
            });
            return;
        }

        if(getValues('Mobile') == null || getValues('Mobile') == undefined || getValues('Mobile').length <9){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุหมายเลขโทรศัพท์นักเรียน'
            });
            return;
        }

        if(getValues('ParentMobile') == null || getValues('ParentMobile') == undefined || getValues('ParentMobile').length <9){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุหมายเลขโทรศัพท์ผู้ปกครอง'
            });
            return;
        }

        if(getValues('ParentMobile') == null || getValues('ParentMobile') == undefined || getValues('ParentMobile').length <9){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุหมายเลขโทรศัพท์ผู้ปกครอง'
            });
            return;
        }
        if(getValues('SchoolCode') === null || getValues('SchoolCode') === undefined){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุที่ โรงเรียน'
            });
            return;
        }
        if(getValues('GPA') == null || getValues('GPA') == undefined || isNaN(Number(getValues('GPA'))) || Number(getValues('GPA')) <=0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุผลการเรียน'
            });
            return;
        }
        if(Number(getValues('GPA')) >4){
            Swal.fire({
                icon: 'error',
                title: 'รูปแบบผลการเรียนไม่ถูกต้อง'
            });
            return;
        }

        if(getValues('Project') == null || getValues('Project') == undefined || isNaN(getValues('Project')) || Number(getValues('Project')) === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดเลือกประเภทโครงการ'
            });
            return;
        }

        const _reqObj = {
            RegulationID : (_RegulationID === undefined ? null :_RegulationID),
            ApplicantID: _Applicant.ApplicantID,
            RegRoundID : _RegRoundID,
            PrefixNameID : getValues('PrefixNameID'),
            FirstName : getValues('FirstName'),
            LastName : getValues('LastName'),
            BirthDay : _BirthDay,
            Age : _Age,
            IdentifyType : _IdentifyType,
            IdentifyID : getValues('IdentifyID'),
            Address : getValues('Address'),
            DistrictID: getValues('Subdistrict').SubdistrictId,
            PostCode : getValues('PostCode'),
            Mobile : getValues('Mobile'),
            ParentMobile : getValues('ParentMobile'),
            SchoolCode : getValues('SchoolCode').SchoolCode,
            SchoolName : getValues('SchoolCode').SchoolName,
            SchoolCityName : getValues('SchoolCode').CityName,
            SchoolProvinceName : getValues('SchoolCode').ProvinceName,
            GPA : Number(getValues('GPA')),
            GPAEnglish : (getValues('GPAEnglish') != null ? Number(getValues('GPAEnglish')):0),
            GPAScience :(getValues('GPAScience') != null ? Number(getValues('GPAScience')):0),
            GPAMath : (getValues('GPAMath') != null ? Number(getValues('GPAMath')):0),

            FirstNameDad : getValues('FirstNameDad'),
            LastNameDad : getValues('LastNameDad'),
            AddressDad : getValues('AddressDad'),
            WorkDad : getValues('WorkDad'),
            PositionDad : getValues('PositionDad'),
            SalaryDad : (getValues('SalaryDad') != null ? Number(getValues('SalaryDad')):0),
            FirstNameMom : getValues('FirstNameMom'),
            LastNameMom : getValues('LastNameMom'),
            AddressMom : getValues('AddressMom'),
            WorkMom : getValues('WorkMom'),
            PositionMom : getValues('PositionMom'),
            SalaryMom : (getValues('SalaryMom') != null ? Number(getValues('SalaryMom')):0),

            Project: Number(getValues('Project')),
        }
        Swal.showLoading();
        API({method : "post",url:"/Application/Save",data:_reqObj}).then(res=>{
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            if(res.data.Status){
                if(_RegulationID === null || _RegulationID === undefined){
                    setRegulationID(res.data.Response.RegulationID);
                }
                setModalFormState(false);
                Swal.fire({
                    title:res.data.Message,
                    html:'โปรดรอสัครู่',
                    timer:2000,
                    showCancelButton: false,
                    showConfirmButton: false
                }).then(()=>{

                    if(_RegulationID === null || _RegulationID === undefined){
                        API({method : "get",url:"/Applicant/"+_RegRoundID+'/File'}).then(res=>{

                            if(!res.data.Status){
                                Swal.fire({
                                    icon: 'error',
                                    title: res.data.Message,
                                });
                                return;
                            }
                            setFileList(res.data.Response);
                            setModalFileState(true);

                        }).catch(error=>{
                            Swal.fire({
                                icon: 'error',
                                title: (error.response != undefined ? 'การทำงานผิดพลาด' :'การเชื่อมต่อผิดพลาด'),
                                text: (error.response != undefined ? error.response.data.Message:error.message+' ['+error.code+']'),
                            });
                        });
                    }else{
                        _LoadApplicationFile(_RegulationID);
                        setModalFileState(true);

                    }
                    Swal.close();
                    _UpdateReload();
                });
                //setRegRound(res.data.Response);
            }
        }).catch(error=>{
            Swal.fire({
                icon: 'error',
                title: (error.response != undefined ? 'การทำงานผิดพลาด' :'การเชื่อมต่อผิดพลาด'),
                text: (error.response != undefined ? error.response.data.Message:error.message+' ['+error.code+']'),
            });
        });
    }
      
    const _ResetValue = ()=>{
        reset();
        setRegRound(null);
        setApplicant(null);
        setFileList([]);
        setBirthDay('');
        setAge('');
        setIdentifyType(1);
    }
    const _RegisterFileOpen =(FileRequestID) =>{
        setFileRequestID(FileRequestID);
        _FileInputRef.current.value = null;
        _FileInputRef.current.click();
    }
    const _RegisterFileUpload = async(e)=>{
        const FileRequestID = _FileRequestID;
        setFileRequestID(undefined);
        const fd = new FormData();
		fd.append("File", _FileInputRef.current.files[0]);
        Swal.showLoading();
        API({method : "post",url:'/File/Application/'+_RegulationID+'/'+_FileRequestID,data:fd,headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            _LoadApplicationFile(_RegulationID);
            Swal.close();
        })
        .catch(error => {
            console.log("Server Error : ", error);
            Swal.close();
        });
        
    }
    const _LoadApplicationFile = async(RegulationID)=>{

        API({method : "get",url:"/Application/"+RegulationID+'/File'}).then(res=>{
            if(res.data.Status){
                setFileList(res.data.Response);
            }
        }).catch(error=>{

        });
        
    }
    const _DeleteFileAction = async(FileID) =>{
        Swal.showLoading();
        API({method : "delete",url:"/File/Application/"+FileID}).then(res=>{
            _LoadApplicationFile(_RegulationID);
            Swal.close();
        })
        .catch(error => {
            Swal.close();
            if(error.response != undefined){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.Message
                });
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: error.message+' ['+error.code+']',
                });
            }
        });
    }
    const _ValidateApplication = async()=>{
        Swal.showLoading();

        API({method : "get",url:"/Application/"+_RegulationID+'/Validate'}).then(res=>{
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
            }
            if(res.data.Status){
                //setFileList(res.data.Response);
                _LoadApplicationForm(_RegulationID);
                Swal.close();
                setModalFileState(false);
                setModalPayment(true);
            }
        }).catch(error=>{
            Swal.close();
            if(error.response != undefined){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.Message
                });
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: error.message+' ['+error.code+']',
                });
            }
        });
    }
    const _PaymentSlip = ()=>{
        _FilePaymentSlipRef.current.value = null;
        _FilePaymentSlipRef.current.click();
    }
    const _PaymentSlipFilePreview = (e)=>{
        const _FileType = String(_FilePaymentSlipRef.current.files[0].type);
        if(!_FileType.includes('image/')){
            Swal.fire({
                icon: 'error',
                title: 'รูปแบบไฟล์ไม่ถูกต้อง',
                text: 'โปรดเลือกไฟล์ประเภทรูปภาพ (PNG,JPEG)'
            });
            return;
        }
        const _URL = URL.createObjectURL(_FilePaymentSlipRef.current.files[0]);
        setPaymentSlipFile(_URL);
        setPaymentQR(false);
    }
    const _PaymentSlipUpload = async()=>{
        const fd = new FormData();
		fd.append("File", _FilePaymentSlipRef.current.files[0]);
        Swal.showLoading();
        API({method : "post",url:'/Application/'+_RegulationID+'/Payment/Transfer',data:fd,headers: { "Content-Type": "multipart/form-data" } }).then(res => {
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return;
            }
            setPaymentStatus(true);
            setModalPayment(false);
            Swal.fire({
                icon: 'success',
                title: 'ท่านได้กดยืนยันการชำระแล้ว',
                text: 'รอการตรวจสอบจากเจ้าหน้าที่เพื่อดำเนินการขั้นตอนต่อไป',
                timer: 5000,
                showCancelButton: false,
                showConfirmButton: false
            }).then(()=>{
                _UpdateReload();
            });
            
        })
        .catch(error => {
            Swal.close();
            if(error.response != undefined){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.Message
                });
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: error.message+' ['+error.code+']',
                });
            }
        });
    }

    const _UpdateReload = ()=>{
        if(props.reload != undefined){
            try{
                props.reload();
            }catch(e){
                console.log(e);
            }
        }
    }
    const _Submit = async()=>{
        Swal.showLoading();
        API({method : "post",url:"/Application/"+_RegulationID+'/Submit'}).then(res=>{
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
            }
            if(res.data.Status){
                _UpdateReload();
                setModalPayment(false);
            }
            Swal.close();
        }).catch(error=>{
            Swal.close();
            if(error.response != undefined){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.Message
                });
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: error.message+' ['+error.code+']',
                });
            }
        });
    }
    return (
        <>
        <Modal isOpen={_ModalFormState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">แบบฟอร์มสมัครนักเรียน</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalFormState(false)}}></button>
            </div>

            <div className='modal-body' style={{minHeight:'300px'}}>
                <div className='row mb-2'>
                    <div className='col-12 d-block d-sm-block d-md-none fw-semibold text-center'>{(_RegRound != null?_RegRound.Name:"")}</div>
                    <div className='col-12 fs-5 d-none d-sm-none d-md-block fw-semibold text-center'>{(_RegRound != null?_RegRound.Name:"")}</div>
                </div>
                <div className='row'>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                            <label className="form-label fw-semibold">คำนำหน้าชื่อ</label>
                                <Controller name="PrefixNameID" control={control} rules={{required:false}} render={({field})=>(
                                    <select className='form-control' onChange={(e)=>field.onChange(e)} value={field.value} defaultValue={field.value} >
                                        <option value={0}>เลือกคำนำหน้าชื่อ</option>
                                        <option value={1}>เด็กชาย</option>
                                        <option value={2}>เด็กหญิง</option>
                                        <option value={3}>นาย</option>
                                        <option value={4}>นางสาว</option>
                                    </select>
                                )}/>
                        </div>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-5'>
                            <label className="form-label fw-semibold">ชื่อ</label>
                            <input type="text" className="form-control" {...register("FirstName", { required: false })}  autoComplete='one-time-code'/>
                        </div>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-5'>
                            <label className="form-label fw-semibold">นามสกุล</label>
                            <input type="text" className="form-control" {...register("LastName", { required: false })}  autoComplete='one-time-code'/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-3'>
                            <label className="form-label fw-semibold">วันเดือนปีเกิด</label>
                            <ThaiDatePicker maxDate={moment().add(-10,'years').toDate()} inputProps={{displayFormat: "D MMMM YYYY",className:'text-center form-control' }} value={_BirthDay} onChange={(c,b)=>{
                                if(c != null && c != undefined){
                                    const AgeCal = moment().diff(c,'years');
                                    if(!isNaN(AgeCal)) setAge(AgeCal); else setAge('');
                                }
                                
                                setBirthDay(c);
                            }}/>
                        </div>

                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-1'>
                            <label className="form-label fw-semibold">อายุ</label>
                            <input type="text" className="form-control text-center" value={_Age} readOnly={true}  autoComplete='one-time-code'/>
                        </div>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-4'>
                            <label className="form-label fw-semibold">{IdentifyTypeName(_IdentifyType)}</label>
                            <input type="text" className="form-control text-center" {...register("IdentifyID", { required: false })} readOnly={true}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12'>
                            <label className="form-label fw-semibold">ที่อยู่ปัจจุบัน</label>
                                <Controller name="Address" control={control} rules={{required:false}} render={({field})=>(
                                    <textarea className='form-control' value={field.value} onChange={(e)=>field.onChange(e)}></textarea>
                                )}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-10'>
                            <label className="form-label fw-semibold">ตำบล / อำเภอ / จังหวัด</label>
                            <Controller name="Subdistrict" control={control} rules={{required:false}} render={({field})=>(
                                <AsyncSelect 
                                    name={field.name}
                                    value={field.value}
                                    placeholder={'ค้นหา ตำบล / อำเภอ / จังหวัด'} 
                                    loadOptions={(e)=>SearchSubDistrict(e)} 
                                    onChange={(e)=>field.onChange(e)}
                                    getOptionLabel={(e)=> e.FullName} 
                                    getOptionValue={(e)=>e.SubdistrictId} />
                            )}/>
                        </div>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                            <label className="form-label fw-semibold">รหัสไปรษณีย์</label>
                            <input type="tel" maxLength={5} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("PostCode", { required: false })}  autoComplete='one-time-code'/>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                            <label className="form-label fw-semibold">หมายเลขโทรศัพท์นักเรียน</label>
                            <input type="tel" maxLength={10} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("Mobile", { required: false })}  autoComplete='one-time-code'/>
                        </div>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                            <label className="form-label fw-semibold">หมายเลขโทรศัพท์ผู้ปกครอง</label>
                            <input type="tel" maxLength={10} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("ParentMobile", { required: false })}  autoComplete='one-time-code'/>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12'>
                            {(_RegRound != null && _RegRound.RegType === 1 ?<label className="form-label fw-semibold">กำลังศึกษาชั้นประถมศึกษาปีที่ 6 โรงเรียน</label> :"")}
                            {(_RegRound != null && _RegRound.RegType === 2 ?<label className="form-label fw-semibold">กำลังศึกษาชั้นมัธยมศึกษาปีที่ 3 โรงเรียน</label> :"")}
                            <Controller name="SchoolCode" control={control} rules={{required:false}} render={({field})=>(
                                    <AsyncSelect 
                                    name={field.name}
                                    value={field.value}
                                    placeholder={'ค้นหาชื่อโรงเรียน'} 
                                    loadOptions={(e)=>SearchSchool(e)} 
                                    onChange={(e)=>{
                                        field.onChange(e);
                                    }}
                                    getOptionLabel={(e)=> e.FullName} 
                                    getOptionValue={(e)=>e.SchoolCode} />
                                )}/>
                        </div>
                    </div>
                    {(_RegRound != null && _RegRound.ApplicationFromType === 0 ?<>
                        <div className='row mt-5'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-12 text-center fw-semibold'>ขอสมัครสอบคัดเลือกเข้า{_RegRound.Name} โดยมีผลการเรียนเฉลี่ยสะสมชั้นประถมศึกษาปีที่ 4 และชั้นประถมศึกษาปีที่ 5  เท่ากับ</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-12 text-center fw-semibold'>ขอสมัครสอบโครงการทุนพิเศษของโรงเรียนวัฒโนทัยพายัพ โดยมีผลการเรียนเฉลี่ยสะสมชั้นมัธยมศึกษาปีที่ 1 และชั้นมัธยมศึกษาปีที่ 2 (4 ภาคเรียน) เท่ากับ</div>
                            :"")}
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-2 pt-2 fw-semibold text-danger text-end'>เกรดเฉลี่ย (GPAX)</div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                <input type="number" min={0} max={(4)} step={0.01} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("GPA", { required: false })}  autoComplete='one-time-code'/>
                            </div>
                        </div>
                    </>:"" )}
                    {(_RegRound != null && (_RegRound.ApplicationFromType === 1 || _RegRound.ApplicationFromType === 2) ?<>
                        <div className='row mt-5'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-12 text-center fw-semibold'>ขอสมัครสอบคัดเลือกเข้า{_RegRound.Name} ชั้นมัธยมศึกษาปีที่ 1 ปีการศึกษา {_RegRound.Year}  โดยมีผลการเรียนเฉลี่ย ดังต่อไปนี้</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-12 text-center fw-semibold'>ขอสมัครสอบคัดเลือกเข้า{_RegRound.Name} ชั้นมัธยมศึกษาปีที่ 4 ปีการศึกษา {_RegRound.Year}  โดยมีผลการเรียนเฉลี่ย ดังต่อไปนี้</div>
                            :"")}
                        </div>
                        <div className='row'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>1. ผลการเรียนเฉลี่ยรวมชั้น ป.4 และ ป.5 เท่ากับ</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>1. ผลการเรียนเฉลี่ยรวม 5 ภาคเรียน</div>
                            :"")}
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                <input type="number" min={0} max={(4)} step={0.01} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("GPA", { required: false })}  autoComplete='one-time-code'/>
                            </div>
                        </div>
                        <div className='row'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>2. ผลการเรียนเฉลี่ยเฉพาะรายวิชาภาษาต่างประเทศ รวมชั้น ป.4 และ ป.5 เท่ากับ</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>2. ผลการเรียนเฉลี่ยเฉพาะรายวิชาภาษาต่างประเทศ 5 ภาคเรียน </div>
                            :"")}
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                <input type="number" min={0} max={(4)} step={0.01} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("GPAEnglish", { required: false })}  autoComplete='one-time-code'/>
                            </div>
                        </div>
                        <div className='row'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>3. ผลการเรียนเฉลี่ยเฉพาะรายวิชาวิทยาศาสตร์และเทคโนโลยี รวมชั้น ป.4 และ ป.5 เท่ากับ</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>3. ผลการเรียนเฉลี่ยเฉพาะรายวิชาวิทยาศาสตร์และเทคโนโลยี 5 ภาคเรียน  เท่ากับ</div>
                            :"")}
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                <input type="number" min={0} max={(4)} step={0.01} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("GPAScience", { required: false })}  autoComplete='one-time-code'/>
                            </div>
                        </div>
                        <div className='row'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>4. ผลการเรียนเฉลี่ยเฉพาะรายวิชาคณิตศาสตร์ รวมชั้น ป.4 และ ป.5 เท่ากับ</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>4. ผลการเรียนเฉลี่ยเฉพาะรายวิชาคณิตศาสตร์ 5 ภาคเรียน  เท่ากับ</div>
                            :"")}
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                <input type="number" min={0} max={(4)} step={0.01} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("GPAMath", { required: false })}  autoComplete='one-time-code'/>
                            </div>
                        </div>
                    </>:"" )}

                    {(_RegRound != null && _RegRound.ApplicationFromType === 3 ?<>
                        <div className='row mt-5'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-12 text-center fw-semibold'>ขอสมัครสอบคัดเลือกเข้า{_RegRound.Name} ชั้นมัธยมศึกษาปีที่ 1 ปีการศึกษา {_RegRound.Year}  โดยมีผลการเรียนเฉลี่ย ดังต่อไปนี้</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-12 text-center fw-semibold'>ขอสมัครสอบคัดเลือกเข้า{_RegRound.Name} ชั้นมัธยมศึกษาปีที่ 4 ปีการศึกษา {_RegRound.Year}  โดยมีผลการเรียนเฉลี่ย ดังต่อไปนี้</div>
                            :"")}
                        </div>
                        <div className='row'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>1. ผลการเรียนเฉลี่ยรวมชั้น ป.4 และ ป.5 เท่ากับ</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>1. ผลการเรียนเฉลี่ยรวม 5 ภาคเรียน</div>
                            :"")}
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                <input type="number" min={0} max={(4)} step={0.01} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("GPA", { required: false })}  autoComplete='one-time-code'/>
                            </div>
                        </div>
                        <div className='row'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>2. ผลการเรียนเฉลี่ยเฉพาะรายวิชาวิทยาศาสตร์และเทคโนโลยี รวมชั้น ป.4 และ ป.5 เท่ากับ</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>2. ผลการเรียนเฉลี่ยรายวิชาวิทยาศาสตร์และเทคโนโลยีพื้นฐาน 5 ภาคเรียน  เท่ากับ</div>
                            :"")}
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                <input type="number" min={0} max={(4)} step={0.01} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("GPAScience", { required: false })}  autoComplete='one-time-code'/>
                            </div>
                        </div>
                        <div className='row'>
                            {(_RegRound != null && _RegRound.RegType === 1 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>4. ผลการเรียนเฉลี่ยเฉพาะรายวิชาคณิตศาสตร์ รวมชั้น ป.4 และ ป.5 เท่ากับ</div>
                            :"")}
                            {(_RegRound != null &&  _RegRound.RegType === 2 ? 
                                <div className='col-lg-10 mt-2 pt-2 fw-semibold text-danger text-end'>4. ผลการเรียนเฉลี่ยรายวิชาคณิตศาสตร์พื้นฐาน 5 ภาคเรียน  เท่ากับ</div>
                            :"")}
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-2'>
                                <input type="number" min={0} max={(4)} step={0.01} className="form-control text-center text-center" onKeyDown={KeyDownNumber} {...register("GPAMath", { required: false })}  autoComplete='one-time-code'/>
                            </div>
                        </div>
                    </>:"" )}

                    <div className='row'>
                        <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-12'>
                            <label className="form-label fw-semibold">ประเภทโครงการ</label>
                            <Controller name="Project" control={control} rules={{required:false}} render={({field})=>(
                                    <select className='form-control' onChange={(e)=>field.onChange(e)} value={field.value} defaultValue={field.value}>
                                        <option value={0}>เลือกประเภทโครงการ</option>
                                        {(_RegRound !=null ? _RegRound.Project.map((v,i)=>{
                                            return (<option key={i} value={v.ProjectID}>{v.ProjectName}</option>);
                                        }):"")}
                                    </select>
                                )}/>
                        </div>
                    </div>

                    {(_RegRound != null && _RegRound.ApplicationFromType === 1  ?<>
                        <div className='row mt-5'>
                            <div className='col-12'>
                                <h5>ข้อมูลบิดา</h5>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">ชื่อ</label>
                                <input type="text" className="form-control" {...register("FirstNameDad", { required: false })} autoComplete='one-time-code' />
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">นามสกุล</label>
                                <input type="text" className="form-control" {...register("LastNameDad", { required: false })} autoComplete='one-time-code' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">ที่อยู่ปัจจุบัน</label>
                                <textarea type="text" className="form-control" {...register("AddressDad", { required: false })} />
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">สถานที่ทำงาน</label>
                                <textarea type="text" className="form-control" {...register("WorkDad", { required: false })} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">ตำแหน่งงาน</label>
                                <input type="text" className="form-control" {...register("PositionDad", { required: false })} autoComplete='one-time-code' />
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">รายได้ต่อเดือน (บาท)</label>
                                <input type="number" className="form-control" {...register("SalaryDad", { required: false })} autoComplete='one-time-code' />
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-12'>
                                <h5>ข้อมูลมารดา</h5>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">ชื่อ</label>
                                <input type="text" className="form-control" {...register("FirstNameMom", { required: false })} autoComplete='one-time-code' />
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">นามสกุล</label>
                                <input type="text" className="form-control" {...register("LastNameMom", { required: false })} autoComplete='one-time-code' />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">ที่อยู่ปัจจุบัน</label>
                                <textarea type="text" className="form-control" {...register("AddressMom", { required: false })} />
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">สถานที่ทำงาน</label>
                                <textarea type="text" className="form-control" {...register("WorkMom", { required: false })} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">ตำแหน่งงาน</label>
                                <input type="text" className="form-control" {...register("PositionMom", { required: false })} autoComplete='one-time-code' />
                            </div>
                            <div className='mt-2 mb-2 col-xs-12 col-sm-12 col-lg-6'>
                                <label className="form-label fw-semibold">รายได้ต่อเดือน (บาท)</label>
                                <input type="number" className="form-control" {...register("SalaryMom", { required: false })} autoComplete='one-time-code' />
                            </div>
                        </div>
                    </>:"" )}

                    {(_Application != null && _Application.CheckRemark != null? <>
                    <div className='row justify-content-center'>
                        <div className='mt-2 mb-2 col-10 text-danger text-center fs-5 alert alert-danger'>
                            {_Application.CheckRemark}
                        </div>
                    </div>
                    </>:'')}
                    <div className='row mt-4'>
                            <div className='col-12 text-center'>
                                <div className='btn-group btn-group-lg'>
                                    <button type='button' className='btn btn-warning text-white' disabled={true}>ขั้นก่อนหน้า</button>
                                    <button type='button' className='btn btn-success' onClick={(e)=>{_Save()}}>ขั้นตอนถัดไป</button>
                                </div>
                            </div>
                    </div>
            </div>
        </Modal>
        <Modal isOpen={_ModalFileState} className='modal-xl' centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">แนบเอกสารสมัคร</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalFileState(false)}}></button>
            </div>
            <div className='modal-body' style={{minHeight:'200px'}}>
                {_FileList.map((v,i)=>(
                    <>
                    <div className='row' key={i}>
                        <div className='col-lg-10 col-8 fw-semibold mt-3 mb-4'>{v.FileRequetName}</div>
                        <div className='col-lg-2 col-4 text-center mt-3 mb-4'><button type='button' className='btn btn-sm btn-success' disabled={(v.Limit >0 && v.Files.length>= v.Limit?true:false)} onClick={()=>{_RegisterFileOpen(v.FileRequetID);}}>เพิ่ม</button></div>
                    </div>
                    {v.Files.map((fv,fi)=>(
                        <div key={fi}>
                            <div className={(fv.CheckStatus === 2? 'row alert alert-danger':'row')}>
                                <div className='col-lg-2 col-12 text-center mt-3 mb-3 '>
                                    <a href={GetAPIURL()+'/File/Application/'+GetAuthorization()+'/'+fv.FileID} target='_blank'>
                                        <img src={GetAPIURL()+'/File/Application/'+GetAuthorization()+'/'+fv.FileID+'/Preview'} style={{'maxHeight':'150px'}}/>
                                    </a>
                                    
                                </div>
                                <div className='col-lg-8 col-12 text-center fw-semibold mt-3 mb-3'>{fv.FileName}</div>
                                <div className='col-lg-2 col-12 text-center mt-3 mb-3'><button type='button' className='btn btn-sm btn-danger' onClick={(e)=>{_DeleteFileAction(fv.FileID)}}>ลบ</button></div>
                            </div>
                            {(fv.CheckStatus != 0?<>
                                <div className={(fv.CheckStatus === 2? 'row alert alert-danger':'row')}>
                                    <div className='col-lg-10 col-10 text-danger text-center fs-5'>
                                        <div className='mt-2'>{fv.CheckRemark}</div>
                                    </div>
                                    <div className='col-lg-2 col-2 text-center'>
                                        {(fv.CheckStatus === 1?
                                            <h5><span class="badge bg-info">ผ่าน</span></h5>
                                        :'')}
                                        {(fv.CheckStatus === 2?
                                            <h5><span class="badge bg-warning mt-4">แก้ไข</span></h5>
                                        :'')}
                                    </div>
                                </div>
                            </>:'')}
                        </div>
                    
                    ))}
                    <hr/>
                    </>
                ))}


                    <div className='row mt-4'>
                        <div className='col-12 text-center'>
                            <div className='btn-group btn-group-lg'>
                                <button type='button' className='btn btn-warning text-white' onClick={(e)=>{setModalFileState(false);setModalFormState(true);}}>ขั้นก่อนหน้า</button>
                                <button type='button' className='btn btn-success' onClick={(e)=>{_ValidateApplication();}}>ขั้นตอนถัดไป</button>
                            </div>
                        </div>
                    </div>
            </div>
        </Modal>

        <Modal isOpen={_ModalPayment} className='modal-xl' centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">
                {(_Application != null && _Application.Payment?'ตรวจสอบข้อมูล':'ตรวจสอบข้อมูลและชำระค่าธรรมเนียมสมัครสอบ')}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalPayment(false)}}></button>
            </div>
            <div className='modal-body' style={{minHeight:'300px'}}>
                <div className='row'>
                    <div className='col-lg-8 col-12 mt-2 mb-2'>
                        <ApplicationFormPDF PDFFile={GetAPIURL()+'/Application/'+_RegulationID+'/'+GetAuthorization()+'/PDF'}/>
                    </div>
                    {(_Application != null && _Application.Payment?<>
                            <div className='col-lg-4 col-12 fs-5 text-center'>
                                <button type='button' className='btn btn-danger' onClick={()=>{_Submit()}}>ส่งใบสมัครสอบ</button>
                            </div>
                    </>:(_Application != null && _Application.PaymentType >0 ? <>
                            <div className='col-lg-4 col-12 fs-5 text-center text-danger'>ท่านได้กดยืนยันการชำระแล้ว  รอการตรวจสอบจากเจ้าหน้าที่เพื่อดำเนินการขั้นตอนต่อไป</div>
                    </>:<>
                    <div className='col-lg-4 col-12'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12 fw-semibold text-danger text-center fs-5'>
                                                ค่าธรรมเนียมสมัครสอบ {(_RegRound != null ? _RegRound.Price :"0")} บาท
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12 mt-4 mb-2 text-center'>
                                               <div className='btn-group btn-lg'>
                                                    <button type='button' className='btn btn-info text-white align-middle fs-5' style={{minWidth:'150px'}} disabled={(_PaymentStatus?true:false)} onClick={()=>{setPaymentType(1);}}>โอนเงิน<br/>ผ่านธนาคาร</button>
                                                    {
                                                        //<button type='button' className='btn btn-primary align-middle fs-5 hide' style={{minWidth:'150px'}} disabled={(_PaymentStatus?true:false)} onClick={()=>{setPaymentType(2);}}>ThaiQR</button>
                                                    }
                                               </div>
                                            </div>
                                        </div>

                                        {(_PaymentType === 1 ?
                                        <>
                                            <div className='row mt-1 mb-1'>
                                                <div className='col-12 fs-5 text-center'>
                                                    <img src={require('../../Assets/Images/KTB.png')}/>
                                                </div>
                                            </div>
                                            <div className='row mt-1 '>
                                                <div className='col-12 fs-5 text-center'>
                                                    ธนาคารกรุงไทย
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-12 fs-5 text-center'>
                                                    เลขที่บัญชี 521-1-47913-0
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-12 fs-5 text-center'>
                                                    ชื่อบัญชี วัฒโนทัยพายัพ (มสธ.)
                                                </div>
                                            </div>
                                            {(!_PaymentStatus ?(_PaymentSlipFile != null?
                                                <>
                                                <div className='row mt-5'>
                                                    <div className='col-12 text-center'>
                                                        <img src={_PaymentSlipFile} style={{maxWidth:'300px'}}/>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-12 fs-5 text-center'>
                                                        <div className='btn-group btn-lg'>
                                                            <button type='button' className='btn btn-success text-white' style={{minWidth:'150px'}} onClick={()=>{_PaymentSlipUpload()}}>ยืนยัน</button>
                                                            <button type='button' className='btn btn-warning text-white' style={{minWidth:'150px'}} onClick={()=>{setPaymentSlipFile(null);}}>ยกเลิก</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                </>
                                            :
                                                <>
                                                <div className='row mt-5'>
                                                    <div className='col-12 fs-5 text-center'>
                                                        <button type='button' className='btn btn-danger' onClick={()=>{_PaymentSlip()}}>แนบหลักฐานการโอนเงิน</button>
                                                    </div>
                                                </div>
                                                </>
                                            ):
                                            <>
                                            <div className='row mt-4 mb-4'>
                                                <div className='col-12 fs-4 fw-semibold text-center text-success'>
                                                    เจ้าหน้าที่กำลังตรวจสอบ
                                                </div>
                                            </div>
                                            </> )}                                            
                                        </>:"")}

                                        {(_PaymentType === 2 ?
                                        <>
                                            <div className='row mt-2 mb-2'>
                                                <div className='col-12 fs-5 text-center'>
                                                    <img style={{maxWidth:'300px'}} src="#"/>
                                                </div>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className='col-12 fs-5 text-center'>
                                                    <button type='button' className='btn btn-danger'>บันทึกรูป ThaiQR</button>
                                                </div>
                                            </div>
                                            <div className='row mt-5'>
                                                <div className='col-12 fs-5 text-center text-danger'>โปรดชำระภายในวันที่ <br/></div>
                                            </div>
                                        </>:<>
                                            
                                        </>)}

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>))}
                    
                </div>
                <div className='row mt-4'>
                    <div className='col-12 text-center'>
                        <div className='btn-group btn-group-lg'>
                            <button type='button' className='btn btn-warning text-white' onClick={(e)=>{setModalFileState(true);setModalPayment(false);}}>ขั้นก่อนหน้า</button>
                            <button type='button' className='btn btn-success' disabled={true}>ขั้นตอนถัดไป</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <input type='file' onChange={(e)=>{_RegisterFileUpload(e)}} ref={_FileInputRef} style={{display: 'none'}} accept=".png,.pdf,.jpg,.jpeg"/>
        <input type='file' ref={_FilePaymentSlipRef} onChange={(e)=>{_PaymentSlipFilePreview(e)}} style={{display: 'none'}} accept=".png,.jpg,.jpeg"/>
        <PDFViewCard ref={PDFViewCard}/>
        </>)
        
});

export default React.memo(RegisterForm)